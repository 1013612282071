import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const DentalImplantsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
			}
			schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "dental-implants" }) {
				slug
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
				dentalImplantsExeterFieldGroups {
					dentalImplantsIntroSection {
						heading
						title
						subtitle
						description
						cta {
							title
							target
							url
						}
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					dentalImplantsTextSection {
						heading
						content
					}
					dentalImplantsAdvantageSection {
						advantages
						advantagesColumn2
						heading
					}
					dentalImplantsPricingSection {
						heading
						plans {
							heading
							price
							content
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
						}
					}
					dentalImplantsLongTextSection
					dentalImplantsFaqSection {
						heading
						faqs {
							nodes {
								... on WpFaq {
									faqFields {
										question
										answer
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, dentalImplantsExeterFieldGroups, slug },
	} = data;

	const {
		dentalImplantsIntroSection,
		dentalImplantsTextSection,
		dentalImplantsAdvantageSection,
		dentalImplantsPricingSection,
		dentalImplantsLongTextSection,
		dentalImplantsFaqSection,
	} = dentalImplantsExeterFieldGroups;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
		image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
		mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
		sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
		description: `${seoFieldGroups?.metaDescription}`,
		logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
		name: "Dental House Exeter",
		url: "https://www.dentalhouseexeter.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
			image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
			name: "Dental House Exeter",
			url: "https://www.dentalhouseexeter.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.dentalhouseexeter.co.uk/reviews",
			datePublished: "2022-03-21T17:58:29+00:00",
			reviewBody:
				"This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
			author: {
				"@type": "Person",
				name: "Caroline P",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Dental House Exeter",
				sameAs: "https://www.dentalhouseexeter.co.uk",
			},
		},
	};

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Treatments",
				item: {
					url: `${siteUrl}/treatments`,
					id: `${siteUrl}/treatments`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Dental Implants",
				item: {
					url: `${siteUrl}/treatments/dental-implants`,
					id: `${siteUrl}/treatments/dental-implants`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/treatments/dental-implants`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{dentalImplantsIntroSection &&
					!checkPropertiesForNull(dentalImplantsIntroSection, ["heading"]) && (
						<TreatmentHero
							img={
								dentalImplantsIntroSection?.image.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={dentalImplantsIntroSection?.image.node?.altText}
							heading1={dentalImplantsIntroSection?.title}
							smallHeadingTopText={dentalImplantsIntroSection?.subtitle}
							heading2={dentalImplantsIntroSection?.heading}
							objPos="right center"
							text={
								<SafeHtmlParser
									htmlContent={dentalImplantsIntroSection?.description}
								/>
							}
							ctaButton={dentalImplantsIntroSection?.cta}
						/>
					)}
				{dentalImplantsTextSection &&
					!checkPropertiesForNull(dentalImplantsTextSection, ["heading"]) && (
						<section className="pt-8 pb-md-6">
							<Container>
								<Row>
									<Col className="">
										<h2 className=" display-4 pb-4 ">
											{dentalImplantsTextSection?.heading}
										</h2>
										<SafeHtmlParser
											htmlContent={dentalImplantsTextSection?.content}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{dentalImplantsAdvantageSection &&
					!checkPropertiesForNull(dentalImplantsAdvantageSection, [
						"heading",
					]) && (
						<section className="py-5 py-lg-7 mt-5 mt-md-0 bg-primary content-list-advantage">
							<Container>
								<Row>
									<Col>
										<h2 className="text-white text-center fs-1 pb-5">
											{dentalImplantsAdvantageSection?.heading}
										</h2>
									</Col>
								</Row>
								<Row>
									<Col lg={5}>
										<SafeHtmlParser
											htmlContent={dentalImplantsAdvantageSection?.advantages}
										/>
									</Col>
									<Col className="d-none d-lg-block text-center" lg={2}>
										<div
											style={{ width: "5px" }}
											className="h-100 mx-auto bg-accent"
										></div>
									</Col>
									<Col lg={5}>
										<SafeHtmlParser
											htmlContent={
												dentalImplantsAdvantageSection?.advantagesColumn2
											}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				{dentalImplantsPricingSection &&
					!checkPropertiesForNull(dentalImplantsPricingSection, [
						"heading",
					]) && (
						<section className="py-5 pb-lg-7">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className=" display-5 pb-4">How much will it cost?</h2>
									</Col>
								</Row>
								{dentalImplantsPricingSection.plans &&
									dentalImplantsPricingSection.plans.length > 0 && (
										<Row className="pt-5 h-100 justify-content-center">
											{" "}
											{dentalImplantsPricingSection.plans.map((cardItem) => (
												<TreatmentCard
													key={uuid4()}
													image={
														cardItem?.image.node?.localFile.childImageSharp
															.gatsbyImageData
													}
													imgAlt={cardItem?.image.node?.altText}
													title={cardItem?.heading}
													text={
														<SafeHtmlParser htmlContent={cardItem?.content} />
													}
													span={4}
													price={cardItem?.price}
													shorter="image-shorter"
												/>
											))}
										</Row>
									)}
							</Container>
						</section>
					)}
				<SafeHtmlParser htmlContent={dentalImplantsLongTextSection} />
				{dentalImplantsFaqSection &&
					!checkPropertiesForNull(dentalImplantsFaqSection, ["faqs"]) && (
						<Faq
							heading={dentalImplantsFaqSection?.heading}
							faqs={dentalImplantsFaqSection?.faqs?.nodes}
						/>
					)}
			</Layout>
		</>
	);
};

export default DentalImplantsPage;
